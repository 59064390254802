import React from 'react';
import { useState, useEffect, useRef, useReducer} from 'react';

import Dropzone from '../Components/Dropzone';
import Dropdown from '../Components/Dropdown';
import UploadPreview from '../Components/UploadPreviews/UploadPreview';
import FilePreview from '../Components/FilePreview';
import FileErrorPreview from '../Components/FileErrorPreview';
import ReportSheet from '../Components/Reporting/ReportSheet.js';
import ErrorPreview from '../Components/UploadPreviews/ErrorPreview';
import SingleUploadPreview from '../Components/UploadPreviews/SingleUploadPreview';
import CardsPreview from '../Components/UploadPreviews/CardsPreview';
import InputField from "../Components/InputField.js";
import { Tooltip } from '@chakra-ui/react';
import * as XLSX from 'xlsx';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelIcon from '@mui/icons-material/Cancel';
import CircularProgress from '@mui/material/CircularProgress';
import Skeleton from '@mui/material/Skeleton';
import ErrorOutlinedIcon from '@mui/icons-material/ErrorOutlined';

import DownloadIcon from '@mui/icons-material/Download';
import MortalityVerificationTemplate from '../MortalityVerificationTemplate.xlsx';
import UploadTemplate from '../UploadTemplate.xlsx';
import badTemplate from '../BadTemplate.xlsx';
import csvTemplate from '../UploadTemplateCSV.csv';
import WarningIcon from '@mui/icons-material/Warning';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import ViewBarBtn from '../Components/ViewBarBtn.js';
import SaveIcon from '@mui/icons-material/Save';
import UserAgreement from '../Components/UploadPreviews/UserAgreement';
import UndoIcon from '@mui/icons-material/Undo';
import ReplayIcon from '@mui/icons-material/Replay';
import RestoreIcon from '@mui/icons-material/Restore';
import ScheduleIcon from '@mui/icons-material/Schedule';
import TopicIcon from '@mui/icons-material/Topic';
import PersonIcon from '@mui/icons-material/Person';
import GroupIcon from '@mui/icons-material/Group';
import ErrorIcon from '@mui/icons-material/Error';
import ContractLimit from '../Components/UploadPreviews/ContractLimit.js';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import BeenhereOutlinedIcon from '@mui/icons-material/BeenhereOutlined';
import BeenhereIcon from '@mui/icons-material/Beenhere';
import DescriptionIcon from '@mui/icons-material/Description';
import { lightGreen } from '@mui/material/colors';
import ContractLimitOverlay from '../Components/Overlays/ContractLimitOverlay.js';
import UserAgreementOverlay from '../Components/Overlays/UserAgreementOverlay.js';
import ForwardOutlinedIcon from '@mui/icons-material/ForwardOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import LockIcon from '@mui/icons-material/Lock';

function Upload(props){
    const session = props?.session;
    const individualData = session?.individual?.data[session?.handler?.data?.currentAccountID];
    const viewType = session?.env?.viewport?.viewType;

    // const rows = session?.upload?.data?.rows;
    const rows = session?.edit?.data?.rows;

    const errorCountData = session?.upload?.data?.errorCountData;
    //const errorCountData = session?.upload?.data?.errorCountData;


    const rowErrors = session?.upload?.data?.rowErrors;
    const updateRows = session?.upload?.functions?.updateRows;

    const importedList = session?.upload?.data?.importedList;
    const editImportedList = session?.edit?.data?.importedList;

    const rowsList = session?.edit?.data?.importedList.list;
    //const rowsList = session?.upload?.data?.importedList.list;

    const updateImportedList = session?.upload?.functions?.updateImportedList;
    const updateEditImportedList = session?.edit?.functions?.updateImportedList;

    const undoEdit = session?.edit?.functions?.undoEdit;
    const clearEditList = session?.edit?.functions?.clearEditList;
    const displayErrors = session?.edit?.data?.displayErrors;
    const filterErrors = session?.edit?.data?.filterErrors;
    const editList = session?.edit?.data?.editList;

    const updateReporting =  session?.reporting?.setData;

    // const undoEdit = session?.upload?.functions?.undoEdit;
    // const clearEditList = session?.upload?.functions?.clearEditList;
    // const displayErrors = session?.upload?.data?.displayErrors;
    // const editList = session?.upload?.data?.editList;
    
    const fileTabSelected = session?.upload?.data?.fileTabSelected;
    const setFileTabSelected = session?.upload?.functions?.setFileTabSelected;

    // const [fileTabSelected, setFileTabSelected] = useState(true);

    const [uploadTabSelected, setUploadTabSelected] = useState(true);

    const [files, setFiles] = useState([]);

    const [sftpFiles, setSftpFiles] = useState([]);

    const [loadingStatus, setLoadingStatus] = useState(false);

    const [fileType, setFileType] = useState();

    const [isChecked, setIsChecked] = useState(false);
    // Uploaded Headers
    const [headers, setHeaders] = useState([]); 
    // List of invalid headers
    const [invalidHeaders, setInvalidHeaders] = useState([]);
    // Boolean if headers are in wrong order
    const [wrongOrder, setWrongOrder] = useState(false);
    // List of headers in incorrect order
    const [incorrectOrder, setIncorrectOrder] = useState([]);
    // List of headers not included
    const [missingHeaders, setMissingHeaders] = useState([]);
    // List of required headers not included
    const [missingRequired, setMissingRequired] = useState([]);
    // Headers to uppercase
    const [upperCaseHeaders, setUpperCaseHeaders] = useState([]);
    
    const [headersProcessed, setHeadersProcessed] = useState(false);

    const [requiredInputs, setRequiredInputs] = useState(false);

    const [invalidInputs, setInvalidInputs] = useState([]);

    const [displayInvalid, setDisplayInvalid] = useState(false);

    const [forceStatus, setForceStatus] = useState(null);

    const [addBeneficiary, setAddBeneficiary] = useState(false);

    // const [largeUpload, setLargeUpload] = useState(false);
    const largeUpload = session?.upload?.data?.largeUpload;

    const [largeUploadCount, setLargeUploadCount] = useState(null);

    // const [largeUploadKeys, setLargeUploadKeys] = useState([])

    // const [largeUploadEdits, setLargeUploadEdits] = useState([]);
    // {
    //     "index" : 0,
    //     "changes" : { "additionalProp1": "string",  "additionalProp2": "string"}
    // }
    const [invalidUpload, setInvalidUpload] = useState(false);

    const individualCount = session?.upload?.data?.prevNumIndividuals;
    const setIndividualCount = session?.upload?.functions?.setPrevIndividuals;

    const largeUploadEdits = session?.edit?.data?.largeUploadEdits;
    const largeUploadKeys = session?.edit?.data?.largeUploadKeys;

    // File details content
    const [maxHeight, setMaxHeight] = useState(1);

    const [animateFirst, setAnimateFirst] = useState(false);
    const [animateLast, setAnimateLast] = useState(false);
    const [animateSSN, setAnimateSSN] = useState(false);
    const [animateDOB, setAnimateDOB] = useState(false);
    const [animateCity, setAnimateCity] = useState(false);
    const [animateState, setAnimateState] = useState(false);
    const [animateZip, setAnimateZip] = useState(false);

    // Single upload dropdowns 
    const [selectedState, setSelectedState] = useState({
        showMenu : false
    });
    const [selectedBenefState, setSelectedBenefState] = useState({
        showMenu : false
    });
    const [selectedBenefRelationship, setSelectedBenefRelationship] = useState({
        showMenu : false
    });
    // Edit Cell Dropdown
    const [editCellDropdownHandler, setEditCellDropdownHandler]  = useState({ showMenu : false });

    const containerRef = useRef(null);
      
    function scrollToBox(boxId) {
        const box = document.getElementById(boxId);
        if (box) {
          box.scrollIntoView({});
        }
      }

    const resetShowMenu = (setDropdownState) => {
        setDropdownState((prev) => {
            // Check if showMenu is already true
            if (prev?.showMenu) {
                return { ...prev, showMenu: false };
            }
            // If showMenu is not true, return the previous state without changes
            return prev;
        });
    };

    const resetDropdowns = () => {
        if(editCellDropdownHandler?.showMenu){
            resetShowMenu(setEditCellDropdownHandler);
        }
    };

    //const [displayErrors, setDisplayErrors] = useState(false);

    //const elementRef = useRef(null);

    //Stops from Upload, 
    const strictRequiredHeaders = [
        'First Name',
        'Last Name',
        'SSN',
        'Date of Birth'
    ];

    const requiredHeaders = [
        'First Name',
        'Last Name',
        'SSN',
        'Date of Birth',
        'City',
        'State',
        'Zip Code' 
    ];

    const requiredSnakeHeaders = [
        'first_name',
        'last_name',
        'ssn',
        'dob',
        'city',
        'state',
        'zip_code',
    ];

    const allHeaders = [
        'Internal ID',
        'Internal Group',
        'First Name',
        'Middle Name',
        'Last Name',
        'Suffix',
        'SSN',
        'Date of Birth',
        'City',
        'State',
        'Zip Code',
        'Beneficiary First Name',
        'Beneficiary Middle Name',
        'Beneficiary Last Name',
        'Beneficiary DOB',
        'Beneficiary City',
        'Beneficiary State',
        'Beneficiary Zip',
        'Beneficiary Relationship'
    ];

    const allSnakeHeaders = [
        'internal_id',
        'internal_group',
        'first_name',
        'middle_name',
        'last_name',
        'suffix',
        'ssn',
        'dob',
        'city',
        'state',
        'zip_code',
        'benef_first_name',
        'benef_middle_name',
        'benef_last_name',
        'benef_dob',
        'benef_city',
        'benef_state',
        'benef_zip',
        'benef_relationship'
    ];

    const beneficiaryHeaders = [
        'First Name',
        'Middle Name',
        'Last Name',
        'Date of Birth',
        'City',
        'State',
        'Zip Code',
        'Relationship'
    ];

    const initialErrorCountData = {
        'file_name': '',
        'total_participants': 0,
        'first_name': 0,
        'last_name': 0,
        //'gender': 0,
        'ssn': 0,
        'dob': 0,
        'city': 0,
        'state': 0,
        'zip_code': 0,
    };

    const statesArray = [
        'ALABAMA', 'AL', 'ALASKA', 'AK', 'ARIZONA', 'AZ', 'ARKANSAS', 'AR', 'CALIFORNIA', 'CA', 'COLORADO', 'CO',
        'CONNECTICUT', 'CT', 'DELAWARE', 'DE', 'FLORIDA', 'FL', 'GEORGIA', 'GA', 'HAWAII', 'HI', 'IDAHO', 'ID',
        'ILLINOIS', 'IL', 'INDIANA', 'IN', 'IOWA', 'IA', 'KANSAS', 'KS', 'KENTUCKY', 'KY', 'LOUISIANA', 'LA',
        'MAINE', 'ME', 'MARYLAND', 'MD', 'MASSACHUSETTS', 'MA', 'MICHIGAN', 'MI', 'MINNESOTA', 'MN', 'MISSISSIPPI', 'MS',
        'MISSOURI', 'MO', 'MONTANA', 'MT', 'NEBRASKA', 'NE', 'NEVADA', 'NV', 'NEW HAMPSHIRE', 'NH', 'NEW JERSEY', 'NJ',
        'NEW MEXICO', 'NM', 'NEW YORK', 'NY', 'NORTH CAROLINA', 'NC', 'NORTH DAKOTA', 'ND', 'OHIO', 'OH', 'OKLAHOMA', 'OK',
        'OREGON', 'OR', 'PENNSYLVANIA', 'PA', 'RHODE ISLAND', 'RI', 'SOUTH CAROLINA', 'SC', 'SOUTH DAKOTA', 'SD', 'TENNESSEE', 'TN',
        'TEXAS', 'TX', 'UTAH', 'UT', 'VERMONT', 'VT', 'VIRGINIA', 'VA', 'WASHINGTON', 'WA', 'WEST VIRGINIA', 'WV',
        'WISCONSIN', 'WI', 'WYOMING', 'WY', 'DC', 'WASHINGTON DC', 'D.C.'
    ];

    const InvalidSSNList = [
        '123456789',
        '987654321',
        '123123123',
        '111111111',
        '222222222',
        '333333333',
        '444444444',
        '555555555',
        '666666666',
        '777777777',
        '888888888',
        '999999999',
        '000000000',
    ];

    const requiredRowTypes = [
        { key: 'First Name', type: 'string' },
        { key: 'Last Name', type: 'string' },
        //{ key: 'Gender', type: 'string' },
        { key: 'Date of Birth', type: 'string' },
        { key: 'SSN', type: 'number' },
        { key: 'City', type: 'string' },
        { key: 'State', type: 'string' },
        { key: 'Zip Code', type: 'string' }
    ];

    const [data, setData] = useState({
        upload: true,
        internal_id: '',
        internal_group: '',
        first_name: '',
        middle_name: '',
        last_name: '',
        suffix: '',
        ssn: '',
        dob: '',
        city: '',
        state: '',
        zip_code: '',
        benef_first_name: '',
        benef_middle_name: '',
        benef_last_name: '',
        benef_dob: '',
        benef_city: '',
        benef_state: '',
        benef_zip: '',
        benef_relationship: '',
    })

    // Set loading status to finished //session?.upload?.data?.errorCountData?.total_participants !== 0
    // useEffect(() => {
    //     if(files.length > 0 && !checkErrorCount() && session?.upload?.data?.errorCountData?.total_participants !== 0){
    //         //setLoadingStatus(false);
    //         console.log("Upload",checkErrorCount(), session?.upload?.data?.errorCountData);
    //     }
    //     console.log("errorCount", session?.upload?.data?.errorCountData); 
    // },[session?.upload?.data?.errorCountData]);

    useEffect(() => {
        setRequiredInputs(true);

        if(!data.first_name){setRequiredInputs(false)};
        if(!data.last_name){setRequiredInputs(false)};
        //if(!data.gender){setRequiredInputs(false)};
        if(!data.ssn){setRequiredInputs(false)};
        if(!data.dob){setRequiredInputs(false)};

    },[data]);

    useEffect(()=>{
        if(files?.length>0){
            handleFileUpload();
        }
    },[files]);

    useEffect(()=>{
        // console.log("Error Count Data:",errorCountData);
    },[errorCountData]);

    useEffect(() => {
        if(fileType === 'text/csv'){      
            if (headers.length > 0 && rows?.length > 0) {
                const headersArray = rows.map(row => Object.keys(row)).flat();
                const emptyHeaders = allHeaders.filter(header => {
                  return rows.every(row => {
                    // Check for empty values, Set missing headers
                    return row[header] === '' || row[header] == null || row[header] === undefined;
                  });
                });
                setMissingHeaders(emptyHeaders);

                // Check if headers are valid
                const newInvalidHeaders = headers.filter(header => !allHeaders.includes(header));
                setInvalidHeaders(newInvalidHeaders);

                // Set missing required
                const missingRequiredVals = requiredHeaders.filter(header => !headersArray.includes(header));
                setMissingRequired(missingRequiredVals);
            }
                    
        }else if(fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || fileType === 'application/vnd.ms-excel'){
            let emptyArr = [];
            setInvalidHeaders(emptyArr);
            setMissingHeaders(emptyArr);
            setMissingRequired(emptyArr);
            setIncorrectOrder(emptyArr);

            // Convert the Map keys to an array
            const headersArray = Array?.from(headers?.values());

            // Convert header names and validate case here
            
            ////// Set Errors //////
            // Check if headers are valid
            const newInvalidHeaders = headers.filter(header => !allHeaders.includes(header));
            setInvalidHeaders(newInvalidHeaders);

            // Set missing headers
            const missingVals = allHeaders.filter(
                header => !headersArray.includes(header)
            );
            setMissingHeaders(missingVals);

            // Set missing required
            const missingRequiredVals = requiredHeaders.filter(
                header => !headersArray.includes(header)
            );
            setMissingRequired(missingRequiredVals);

            const uppercaseHeaders = headers.map(header => header?.toUpperCase());
            setUpperCaseHeaders(uppercaseHeaders);
        }

    },[headers]);

    useEffect(() => {
        // Set wrong header order
        let isWrongOrder = false;
        let headerCount = 0;
        let incorrectOrderArray = [];

        if(fileType === 'text/csv'){ 
            const CSVHeaders = headers.filter(
                header => !missingHeaders.includes(header)
            );
            CSVHeaders.forEach((header) => {
                
                while (missingHeaders.includes(allHeaders[headerCount])) {
                headerCount++; // Skip the missing headers in the allHeaders list.
                }
            
                if (allHeaders[headerCount] !== header) {
                incorrectOrderArray.push(header); // If it doesn't match, add to incorrectOrder.
                isWrongOrder = true;
                headerCount++; 
                } else {
                headerCount++; // If it matches, move to the next header in the allHeaders list.
                }
            });
        }
        if(fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || fileType === 'application/vnd.ms-excel'){
            headers.forEach((header) => {
                
                while (missingHeaders.includes(allHeaders[headerCount])) {
                headerCount++; // Skip the missing headers in the allHeaders list.
                }          
                if (allHeaders[headerCount] !== header) {

                headerCount++; 
                } else {
                headerCount++; // If it matches, move to the next header in the allHeaders list.
                }
            });
        }

    },[missingHeaders]);

    useEffect(() => {
        // Row Change is set in handleFileUpload
        session?.upload?.functions?.updateReport(`rowChangeCount`, null, session?.upload?.data?.rowChangeCount + 1);
        
        // Set Errors //
        
        // if(fileType === 'text/csv'){
        //     let invalidTypeArr = [];
        //     setInvalidType(false);
        //     rows?.forEach(row => {
        //         for(let key in row) {
        //             if(getTypeForKey(key) !== inferDataType(row[key])){
        //                 if(requiredHeaders.includes(key)){
        //                     setInvalidType(true);
        //                     if(!invalidTypeArr.includes(key)){invalidTypeArr.push(key)};
        //                 }
        //             }
        //         }
        //     });
        // }else 
        if((fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || fileType === 'application/vnd.ms-excel') && (session?.upload?.data?.rowChangeCount == 1)){
            // eraseErrorCountData();
            setLoadingStatus(false); // Set loading status to true immediately

            // Convert calculateErrorCounts to an async function
            const calculateErrorCountsAsync = async () => {
                let errorCountNums = 0;
                if(!largeUpload){
                    errorCountNums = await calculateErrorCounts(rows);

                    // Set Error Count number after calculation is complete 
                    session?.upload?.setData(prevData => ({
                        ...prevData,
                        errorCountData: errorCountNums,
                        initialErrorCount: errorCountNums
                    }));
                    session?.edit?.setData(prevData => ({
                        ...prevData,
                        uploadCount: rows?.length
                    }));

                    setLoadingStatus(false); // Set loading status to false after the async operation
                }
            };

            calculateErrorCountsAsync();
            // if(!session?.upload?.data?.largeUpload){
            //     calculateErrorCountsAsync();
            // }
            
        }

        // Was rows, changed to missing required
    },[missingRequired,rows]);

    useEffect(() => {
        if(individualData?.selectedProfile?.active?.upload && individualData?.selectedProfile?.active?.upload === "success"){
            //Clear single upload inputs
            setData({
                internal_id: '',
                internal_group: '',
                first_name: '',
                middle_name: '',
                last_name: '',
                suffix: '',
                ssn: '',
                dob: '',
                city: '',
                state: '',
                zip_code: '',
                benef_first_name: '',
                benef_middle_name: '',
                benef_last_name: '',
                benef_dob: '',
                benef_city: '',
                benef_state: '',
                benef_zip: '',
                benef_relationship: '',
            });
        }
    },[individualData?.selectedProfile?.active]); 

    useEffect(() => {
        // getTotalIndividuals();
        setIndividualCount(session?.account?.data[session?.handler?.data?.currentAccountID]?.summaryData?.totalIndividuals)
        const updateMaxHeight = () => {
            const referenceElement = document.getElementById('referenceElement');
            if (referenceElement) {
                // Set the max-height to be 8 times the height of the reference element
                setMaxHeight(referenceElement.offsetHeight);
            }
        };

        // Run once after the component mounts
        updateMaxHeight();

        // Add event listener for window resize
        window.addEventListener('resize', updateMaxHeight);

        // Cleanup function to remove the event listener on component unmount
        return () => window.removeEventListener('resize', updateMaxHeight);
    }, []); 

    useEffect(() => {
        // Set Contract Limit
        const sessionAccountString = localStorage.getItem('sessionAccount');
        let sessionAccount = null;
        if (sessionAccountString) {
            sessionAccount = JSON.parse(sessionAccountString);
        }
        session?.upload?.setData(prevData => ({
            ...prevData,
            contractLimit: sessionAccount[session?.handler?.data?.currentAccountID]?.contractedRecords    
        }));
     },[session?.upload?.data?.accountNumber]);

    useEffect(() => {
        setAnimateFirst(true);       
        const timer = setTimeout(() => setAnimateFirst(false), 500); 
        return () => clearTimeout(timer); 
    }, [errorCountData.first_name]); 
    useEffect(() => {
        setAnimateLast(true);       
        const timer = setTimeout(() => setAnimateLast(false), 500); 
        return () => clearTimeout(timer); 
    }, [errorCountData.last_name]); 
    useEffect(() => {
        setAnimateSSN(true);       
        const timer = setTimeout(() => setAnimateSSN(false), 500); 
        return () => clearTimeout(timer); 
    }, [errorCountData.ssn]); 
    useEffect(() => {
        setAnimateDOB(true);       
        const timer = setTimeout(() => setAnimateDOB(false), 500); 
        return () => clearTimeout(timer); 
    }, [errorCountData.dob]); 
    useEffect(() => {
        setAnimateCity(true);       
        const timer = setTimeout(() => setAnimateCity(false), 500); 
        return () => clearTimeout(timer); 
    }, [errorCountData.city]); 
    useEffect(() => {
        setAnimateState(true);       
        const timer = setTimeout(() => setAnimateState(false), 500); 
        return () => clearTimeout(timer); 
    }, [errorCountData.state]); 
    useEffect(() => {
        setAnimateZip(true);       
        const timer = setTimeout(() => setAnimateZip(false), 500); 
        return () => clearTimeout(timer); 
    }, [errorCountData.zip_code]); 

    useEffect(() => {
        (checkHeaders(strictRequiredHeaders, headers) !== 0 && files?.length > 0 && setInvalidUpload(true));
        (checkHeaders(strictRequiredHeaders, headers) === 0 && files?.length > 0 && setInvalidUpload(false));
    },[loadingStatus]); 

    useEffect(() => {
        if(session?.upload?.data?.singleUploadSuccess){
            clearSingleData();
            session?.upload?.setData(prevData => ({
                ...prevData,
                singleUploadSuccess: false
            }));
        }
    },[session?.upload?.data?.singleUploadSuccess]); 

    useEffect(() => {
        if(session?.upload?.data?.removeFile){
            setFiles([]);
            setHeaders([]);
            session?.upload?.setData(prevData => ({
                ...prevData,
                removeFile: false
            }));
        }
    },[session?.upload?.data?.removeFile]); 

    useEffect(() => {
        //console.log(rows);
    },[rows]); 

    

    const getTypeForKey = (key) => {
        const matchedType = requiredRowTypes.find(item => item.key === key);
        return matchedType ? matchedType.type : 'unknown';
    }

    const inferDataType = (value) => {
        // Check for numeric value
        if (!isNaN(value) && !isNaN(parseFloat(value))) {
          return 'number';
        }
        // Check for boolean value
        if (value.toLowerCase() === 'true' || value.toLowerCase() === 'false') {
          return 'boolean';
        }
        // Check for date value 
        if (Date?.parse(value)) {
          return 'number'; // Number so that it matches the excel type checker
        }
        // Default to string
        return 'string';
    }

    const eraseErrorCountData = () =>{
        //session?.upload?.setData(prevData => ({
        session?.upload?.setData(prevData => ({
            ...prevData,
            errorCountData: {
                ...prevData.errorCountData,
                'first_name': 0,
                'last_name': 0,
                'ssn': 0,
                'dob': 0,
                'city': 0,
                'state': 0,
                'zip_code': 0
            }
        }));
        //console.log("erased on upload");
    }

    const clearSingleData = () => {
        setData({
            internal_id: '',
            internal_group: '',
            first_name: '',
            middle_name: '',
            last_name: '',
            suffix: '',
            ssn: '',
            dob: '',
            city: '',
            state: '',
            zip_code: '',
            benef_first_name: '',
            benef_middle_name: '',
            benef_last_name: '',
            benef_dob: '',
            benef_city: '',
            benef_state: '',
            benef_zip: '',
            benef_relationship: '',
        });
    }

    const eraseBeneficiaryData = () =>{
        data.benef_first_name = '';
        data.benef_middle_name = '';
        data.benef_last_name = '';
        data.benef_dob = '';
        data.benef_city = '';
        data.benef_state = '';
        data.benef_zip = '';
        data.benef_relationship = '';
    }

    const setFileRows = (newValue) =>{
        //session?.upload?.setData(prevData => ({
        session?.edit?.setData(prevData => ({
            ...prevData,
            rows: []
        }));
        setFiles([]);
        setHeaders([]);
        setIsChecked(false);
    }

    const readExcel = (file) => {
        const reader = new FileReader();
        reader.onload = function (event) {
            try{
                const data = event.target.result;
                const workbook = XLSX.read(data, { type: 'binary' });
                const worksheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[worksheetName];

                // Convert worksheet to JSON
                const json =  XLSX.utils.sheet_to_json(worksheet, { header: 0, defval: '' });
                
                if (json.length > 0) {
                    // Get headers
                    const newHeaders = Object.keys(json[0]);
                    // Generate Columns for file preview
                    generateReportColumns(newHeaders);
                    // Update Headers to snake case
                    const transformedJson = json.map(item => {
                        const newItem = {};
                        newHeaders.forEach(header => {
                            let newKey = null;
                            if (header?.toUpperCase() === "Internal Group"?.toUpperCase()){                               
                                newKey = 'internal_group';
                            }
                            else if (header?.toUpperCase() === "SSN"){
                                newKey = 'ssn';
                            }
                            else{
                                newKey = findKeyByAttribute(session?.individual.data[session?.handler?.data?.currentAccountID].individualAttributesData, 'friendlyTerm', header);
                            }

                            if (newKey) {
                                newItem[newKey] = item[header];
                            } else {
                                newItem[header] = item[header]; // retain original header if no match found
                            }
                        });
                        return newItem;
                    });

                    setHeaders(newHeaders);

                    // Erase error count
                    eraseErrorCountData();
                    
                    // Set Rows in imported list 
                    //updateImportedList("list", processJsonArray(transformedJson))
                    updateEditImportedList("list", processJsonArray(transformedJson))
                    // Set Rows in Data
                    //session?.upload?.setData(prevData => ({
                    session?.edit?.setData(prevData => ({
                        ...prevData,
                        rows: processJsonArray(transformedJson)
                    })); 
                    //setRows(processJsonArray(json));
                    setHeadersProcessed(true);
                }
            } catch(error){

            }
        };
        reader.readAsBinaryString(file);
    };

     // attempt to speed up upload
    function calculateErrorCounts(rows, isLargeUpload) {
        const errorCounts = {
            'file_name':files[0].name,
            'total_participants': 0,
            'first_name': 0,
            'last_name': 0,
            'ssn': 0,
            'dob': 0,
            'city': 0,
            'state': 0,
            'zip_code': 0,
        };

        let rowErrorArr = [];
        let rowErrorKeys = [];
        let rowCount = 0;
        
        if(!isLargeUpload){
            eraseErrorCountData();
        }

        // Iterate through rows and increment the error counts
        rows.forEach(row => {
            session?.edit?.data?.importedList?.columns.map((header, headerIndex) => {
                // Trim any string entry
                if (typeof row[header?.columnName] === 'string') {
                    row[header?.columnName] = row[header?.columnName].trim();
                }

                // Checks if First and Last name only contain letters, spaces, hyphens, and apostrophes 
                if((header?.columnName === 'first_name' || header?.columnName === 'last_name') && !/^[A-Za-z-' ]+$/.test(String(row[header?.columnName]))){
                        rowErrorArr.push({ row: rowCount, column: headerIndex+1 });  
                        rowErrorKeys.push(`${rowCount}-${headerIndex+1}Invalid`);
                        errorCounts[header?.columnName]++;               
                }
                // Checks if SSN is 9 digits
                if(header?.columnName === 'ssn'){
                    const cleanedSSN = String(row[header?.columnName]).replace(/-/g, ''); // Remove dashes

                    if (String(row[header?.columnName]).length === 11 && cleanedSSN.length === 9 && /^\d{9}$/.test(cleanedSSN)) {
                        if(InvalidSSNList.includes(cleanedSSN)){
                            if(!rowErrorArr.some(e => e.row === rowCount && e.column === headerIndex)){
                                rowErrorArr.push({ row: rowCount, column: headerIndex+1 });    
                                rowErrorKeys.push(`${rowCount}-${headerIndex+1}Invalid`);
                                errorCounts[header?.columnName]++;              
                            }; 
                        }
                        rows[rowCount] = { ...rows[rowCount], 'ssn': cleanedSSN};
                    }
                    else if (cleanedSSN.length !== 9 || !/^\d{9}$/.test(cleanedSSN) || InvalidSSNList.includes(cleanedSSN)) {
                            rowErrorArr.push({ row: rowCount, column: headerIndex+1 });    
                            rowErrorKeys.push(`${rowCount}-${headerIndex+1}Invalid`);
                            errorCounts[header?.columnName]++;        
                    }
                }
                // dob
                if(header?.columnName === 'dob' && (!isValidJSDate(row[header?.columnName]))){                     
                        rowErrorArr.push({ row: rowCount, column: headerIndex+1 });    
                        rowErrorKeys.push(`${rowCount}-${headerIndex+1}Invalid`); 
                        errorCounts[header?.columnName]++;                  
                }         
                // Checks that City is a string
                if(header?.columnName === 'city' && !/^[A-Za-z-' .]+$/.test(String(row[header?.columnName]))){
                        rowErrorArr.push({ row: rowCount, column: headerIndex+1 });  
                        rowErrorKeys.push(`${rowCount}-${headerIndex+1}Invalid`);   
                        errorCounts[header?.columnName]++;                  
                }    
                // Checks that State is a string
                if(header?.columnName === 'state' &&  !statesArray.includes(String(row[header?.columnName])?.toUpperCase())){
                        rowErrorArr.push({ row: rowCount, column: headerIndex+1 });    
                        rowErrorKeys.push(`${rowCount}-${headerIndex+1}Invalid`); 
                        errorCounts[header?.columnName]++;                   
                }    
                // Checks that Zip Code is a string
                if(header?.columnName === 'zip_code' && /^\d{5}(?:-\d{4})?$/.test(row[header?.columnName]) && row[header?.columnName].length === 10 ){
                    rows[rowCount] = { ...rows[rowCount], 'zip_code': row[header?.columnName].slice(0, 5)};
                }
                else if(header?.columnName === 'zip_code' && !/^\d{5}(?:-\d{4})?$/.test(row[header?.columnName]) ){
                    rowErrorArr.push({ row: rowCount, column: headerIndex+1 });    
                    rowErrorKeys.push(`${rowCount}-${headerIndex+1}Invalid`); 
                    errorCounts[header?.columnName]++;                   
                }    
            });
            rowCount++;
            errorCounts['total_participants']++;  
        });
        session?.edit?.setData(prevData => ({
                ...prevData,
                rowErrors: rowErrorArr,
                rowErrorKeys : rowErrorKeys,
        }));
        updateEditImportedList("list", rows, false);

        return errorCounts;
    }
    function generateReportColumns(headers){
        headers.forEach(header => {
            session?.upload?.allColumns?.forEach(column => {
                if (header?.toUpperCase() === "Internal Group"?.toUpperCase()){
                    if (header?.toUpperCase() === column?.alternateTerm?.toUpperCase()){
                        //updateImportedList("columns", column, true);
                        updateEditImportedList("columns", column, true);
                    }
                }    
                else if (header?.toUpperCase() === "SSN"){
                    if (header === column?.mobileFriendlyTerm){
                        //updateImportedList("columns", column, true);
                        updateEditImportedList("columns", column, true);
                    }
                }   
                else if (header?.toUpperCase() === column?.friendlyTerm?.toUpperCase()){
                    //updateImportedList("columns", column, true);
                    updateEditImportedList("columns", column, true);
                }
            })
        });
    }

    function excelDateToJSDate(serial) {
        const utcDays = Math.floor(serial - 25569);
        const utcValue = utcDays * 86400; 
        const dateInfo = new Date(utcValue * 1000);
    
        const dateString = dateInfo.toISOString().split('T')[0]; // Format as 'YYYY-MM-DD'
        return dateString;
    }

    // if Input is string
    function convertToDateObject(dateString) {
        // Remove any non-alphanumeric characters from the date string
        const sanitizedDateString = dateString.replace(/[^\w\s]/gi, '');
      
        // Split the sanitized date string into its components based on the delimiter
        const components = sanitizedDateString.split(/[\/\-]/);
        
        // Extract the day, month, and year components
        let day, month, year;
      
        if (components.length === 3) {
          // Assuming the format is month/day/year or day/month/year
          [month, day, year] = components;
        } else if (components.length === 2) {
          // Assuming the format is month/year or day/year
          [month, year] = components;
          // Set day to 1 by default if not provided
          day = 1;
        } else {
          // If the format doesn't match any of the expected formats, return null
          return null;
        }
      
        // Convert month and day to numbers (removing leading zeros)
        month = parseInt(month, 10);
        day = parseInt(day, 10);
      
        // Attempt to create a Date object from the components
        const dateObject = new Date(year, month - 1, day);
      
        
        // Check if the dateObject is valid
        if (isNaN(dateObject.getTime())) {
          // If the date is invalid, return null
          return null;
        }
      
        
        // Return the date object
        return dateObject;
      }
    
    function processJsonArray(json) {
        return json.map((jsonObj, index) => {
            const processedObj = { ...jsonObj };

            if (processedObj["dob"]  && isValidExcelDate(processedObj["dob"])) {
                processedObj["dob"] = excelDateToJSDate(processedObj["dob"]);   
            }else{
                //["dob"] = convertToDateObject(processedObj["dob"]);
                //console.log(convertToDateObject(processedObj["dob"]));
            }
    
            if (processedObj["benef_dob"] && isValidExcelDate(processedObj["benef_dob"])) {
                processedObj["benef_dob"] = excelDateToJSDate(processedObj["benef_dob"]);
            }else{
                //processedObj["benef_dob"] = convertToDateObject(processedObj["benef_dob"]);
            }
    
            return processedObj;
        });
    }

    function toggleDisplayErrors(){
        if(!displayErrors){
            //eraseErrorCountData();
        }
        session?.edit?.setData(prevData => ({
            ...prevData,
            displayErrors: !displayErrors
        }));   
    }

    function toggleFilter(){
        session?.edit?.setData(prevData => ({
            ...prevData,
            filterErrors: !filterErrors
        }));   
    }

    function findKeyByAttribute(obj, attr, value) {
        for (let key in obj) {
            if (obj.hasOwnProperty(key) && obj[key][attr]?.toUpperCase() === value?.toUpperCase() ) {
                return key;
            }
        }
        return null;
    }
    
    const handleFileUpload = (e) => {
        if(files.length>0){
            const file = files[0];
            //console.log(file);
            if (file.type === 'text/csv') {
                setFileType(file.type);
                //readCSV(file);
            } else if (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || file.type === 'application/vnd.ms-excel') {
                session?.edit?.setData(prevData => ({
                    ...prevData,
                    editList: [],
                    displayErrors: true,
                    filterErrors: false
                }));   
                session?.edit?.functions?.updateImportedList("columns", []);
                session?.upload?.functions?.updateReport('rowChangeCount', null, 0);
                session?.upload?.functions?.updateReport("errorCountData", session?.upload?.data?.initialErrorCountData);
                setFileType(file.type);    

                // if(file.size > 3500000){
                //     session?.upload?.setData(prevData => ({
                //         ...prevData,
                //         largeUpload: true,
                //     }));   
                //     sendExcel(file);
                // }else{
                //     session?.upload?.setData(prevData => ({
                //         ...prevData,
                //         largeUpload: false,
                //     }));   
                //     readExcel(file); 
                // }

                readExcel(file); 

            } else {
                //console.log('Unsupported file type');
            }
        };
    }

    function extractExcelHeaders(file) {
        return new Promise((resolve, reject) => {
            if (!file) {
                reject('No file provided');
                return;
            }
    
            const reader = new FileReader();
            reader.onload = function (event) {
                try {
                    const data = new Uint8Array(event.target.result);
                    const workbook = XLSX.read(data, { type: 'array', sheetRows: 1 }); // Read only the first row
    
                    const sheetName = workbook.SheetNames[0];
                    const worksheet = workbook.Sheets[sheetName];
    
                    // Convert sheet to JSON
                    const json = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
    
                    // Get the first row (headers)
                    const headers = json[0];
              
                    resolve(headers); // Resolve the promise with the headers 
                    // console.log('Headers:', headers);
                    generateReportColumns(headers);
                    setHeaders(headers);
                    //console.log("headers:",headers);
                    
                } catch (error) {
                    reject(error);
                }
                
            };
    
            reader.onerror = function (error) {
                reject(error);
            };
    
            reader.readAsArrayBuffer(file);
        });
    }

    function sendExcel(file){
        setLoadingStatus(true);

        // extractExcelHeaders(file);
        // console.log("New Headers:",newHeaders);
        // setHeaders(newHeaders);

        const myHeaders = new Headers();
        // myHeaders.append("Authorization", `Bearer ${session?.user?.data?.sessionID}`) 
        // myHeaders.append("Accept", "*/*");

        const formData = new FormData();
        formData.append("ChunkSize",5000);
        formData.append("File", file);
        
        const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formData,
        // redirect: "follow"
        };

        fetch("https://localhost:7120/api/ExcelParser/Insert", requestOptions)
        .then(response => {
            // Check if the response status is 200
            if (response.ok) {
                return response.json();
            } else {
                throw new Error('Network response was not ok.');
            }
        })
        .then(resData => {

            generateReportColumns(resData?.headers);
            setHeaders(resData?.headers);
            setLoadingStatus(false);
            console.log(resData); 
            const fileKey = resData?.insertionResult?.key;
            setLargeUploadCount(resData?.items);

            // Set errors from response here 
            console.log("resErrors",resData?.errors)
            session?.upload?.setData(prevData => ({
                ...prevData,
                errorCountData: resData?.errors,
                initialErrorCount: resData?.errors
            }));
            // setErrorCount(resData?.errors);

            if (fileKey) {
                // console.log('File Key:', fileKey);
                GetQueryKeys(fileKey);
            } else {
                session?.env?.setOverlay("uploadFailure");
                //setErrorStatus(resData.message);
            }
        })
        .catch(error => {
            console.error('Error:', error);
            setLoadingStatus(false);
            session?.env?.setOverlay("uploadFailure");
            //setErrorStatus(error.message);
        });
    }

    function GetQueryKeys(key){
        setLoadingStatus(true);
        const myHeaders = new Headers();
        // myHeaders.append("Authorization", `Bearer ${session?.user?.data?.sessionID}`)
        // myHeaders.append("Accept", "*/*");

        const formData = new FormData();
        formData.append("key",key);
        
        const requestOptions = {
        method: "GET",
        headers: myHeaders,
        // body: formData,
        // redirect: "follow"
        };

        fetch(`https://localhost:7120/api/ExcelParser/QueryKeys?key=${encodeURIComponent(key)}`, requestOptions)
        .then(response => {
            // Check if the response status is 200 
            if (response.ok) {
                return response.json();
            } else {
                throw new Error('Network response was not ok.');
            }
        })
        .then(resData => {
            setLoadingStatus(false);
            // console.log(resData); 
            session?.edit?.setData(prevData => ({
                ...prevData,
                largeUploadKeys: resData?.keys,
            }));  
            // setLargeUploadKeys(resData?.keys);
            const fileKeys = resData?.keys;
            if (fileKeys) {
                // console.log('File Keys:', fileKeys, resData);
                OpenChunk(fileKeys[0]);
            } else {
                session?.env?.setOverlay("uploadFailure");
                //setErrorStatus(resData.message);
            }
        })
        .catch(error => {
            console.error('Error:', error);
            setLoadingStatus(false);
            session?.env?.setOverlay("uploadFailure");
            //setErrorStatus(error.message);
        });
    }

    function snakeCaseToNormalCase(snakeCaseString) {
        return String(snakeCaseString).replace(/_/g, ' ');
    }

    function OpenChunk(key){
        const myHeaders = new Headers();
        // myHeaders.append("Authorization", `Bearer ${session?.user?.data?.sessionID}`)
        // myHeaders.append("Accept", "*/*");

        const formData = new FormData();
        formData.append("key",key);
        
        const requestOptions = {
        method: "GET",
        headers: myHeaders,
        // body: formData,
        // redirect: "follow"
        };

        fetch(`https://localhost:7120/api/ExcelParser/Query?key=${encodeURIComponent(key)}`, requestOptions)
        .then(response => {
            // Check if the response status is 200
            if (response.ok) {
                return response.json();
            } else {
                throw new Error('Network response was not ok.');
            }
        })
        .then(resData => {
            setLoadingStatus(false);
            // console.log(resData); 
            const filePreview = resData
            if (resData) {
                
                updateEditImportedList("list", processJsonArray(resData));
                session?.edit?.setData(prevData => ({
                    ...prevData,
                    rows: processJsonArray(resData)
                })); 
                setHeadersProcessed(true);

                calculateErrorCounts(processJsonArray(resData), true);
                // console.log(resData);
            } else {
                session?.env?.setOverlay("uploadFailure");
                //setErrorStatus(resData.message);
            }
        })
        .catch(error => {
            console.error('Error:', error);
            setLoadingStatus(false);
            session?.env?.setOverlay("uploadFailure");
            //setErrorStatus(error.message);
        });
            
    }

    function incrementHeader(header) {
        //session?.upload?.setData(prevData => {
        session?.upload?.setData(prevData => {
            const newErrorCountData = { ...prevData.errorCountData };
    
            switch(header) {
                case 'first_name':
                    newErrorCountData['first_name'] = (newErrorCountData['first_name'] || 0) + 1;
                    break;
                case 'last_name':
                    newErrorCountData['last_name'] = (newErrorCountData['last_name'] || 0) + 1;
                    break;
                case 'ssn':
                    newErrorCountData['ssn'] = (newErrorCountData['ssn'] || 0) + 1;
                    break;
                case 'dob':
                    newErrorCountData['dob'] = (newErrorCountData['dob'] || 0) + 1;
                    break;
                case 'city':
                    newErrorCountData['city'] = (newErrorCountData['city'] || 0) + 1;
                    break;
                case 'state':
                    newErrorCountData['state'] = (newErrorCountData['state'] || 0) + 1;
                    break;
                case 'zip_code':
                    newErrorCountData['zip_code'] = (newErrorCountData['zip_code'] || 0) + 1;
                    break;
            }
            return {
                ...prevData,
                errorCountData: newErrorCountData
            };
        });
    }

    function checkErrorCount(errorCountData) {
        if (!errorCountData) {
            return false; 
        }
        return Object.entries(errorCountData)
                     .filter(([key, _]) => key !== 'total_participants' && key !== 'file_name')
                     .every(([_, value]) => value === 0);
    }

    function isNumber(value) {
        return !isNaN(parseFloat(value)) && isFinite(value);
    }

    // function isValidDate(d) {
    //     if(d === NaN){
    //         return false;
    //     }else{
    //         console.log("d is: ",d)
    //         return d instanceof Date && !isNaN(d);
    //     }
    // }

    function isValidJSDate(dateInput) {
        const date = new Date(dateInput);
        return !isNaN(date.getTime());
    }

    // function isValidDateString(dateString) {       
    //     const date = new Date(dateString);
    //     console.log("date:",date);
    //     // Check if the date is Invalid Date or the string is not a valid representation
    //     return !isNaN(date.getTime()) && date.toISOString().startsWith(dateString.substring(0, 10));
    // }

    function isValidDateString(dateString) {
        // Check if dateString matches the format MM/DD/YYYY
        const regex = /^\d{2}\/\d{2}\/\d{4}$/;
        if (!regex.test(dateString)) {
            return false;
        }
    
        // Parse the date parts to integers
        const parts = dateString.split('/');
        const month = parseInt(parts[0], 10);
        const day = parseInt(parts[1], 10);
        const year = parseInt(parts[2], 10);
    
        // Check the ranges of month and year
        if (year < 1000 || year > 3000 || month === 0 || month > 12) {
            return false;
        }
    
        // Create a new date instance to check further
        const date = new Date(year, month - 1, day);
    
        // Check if the date is valid
        return date.getFullYear() === year && date.getMonth() === month - 1 && date.getDate() === day;
    }

    function isValidExcelDate(serial) {
        if (typeof serial !== 'number' || isNaN(serial) || serial < 1) {
            return false;
        }
        // You might want to add more specific checks based on your data requirements
        return true;
    }    

    function checkHeaders(requiredHeaders, headers) {
        let allIncluded = true;
        let someIncluded = false;
    
        for (let header of requiredHeaders) {
            if (upperCaseHeaders.includes(header?.toUpperCase())) {
                someIncluded = true;
            } else {
                allIncluded = false;
            }
        }
    
        if (allIncluded) {
            return 0; // All headers are included
        } else if (someIncluded) {
            return 1; // Some headers are included
        } else {
            return 2; // None of the headers are included
        }
    }

    const handleSingleUpload = () => {
        let validInput = true;
        let invalidInputsArr = [];
        if (!(isNumber(data?.ssn) && data?.ssn.length == 9)) {
            validInput = false;
            invalidInputsArr.push("ssn");
        }
        if(!/^[A-Za-z-' ]+$/.test(data?.first_name)){
            validInput = false;
            invalidInputsArr.push("first_name");
        }
        if(!/^[A-Za-z-' ]+$/.test(data?.last_name)){
            validInput = false;
            invalidInputsArr.push("last_name");
        }   
        // if (!/^(male|female)$/i.test(data?.gender)) {
        //     validInput = false;
        //     invalidInputsArr.push("gender");
        // }
        setInvalidInputs(invalidInputsArr);
        return validInput;
    }
    
    const handleInvalidUpload = () => { 
        setDisplayInvalid(true);
    }

    function updateForceStatus(e, key){
        e.stopPropagation();
        setForceStatus(key);
    }

    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
    };

    const downloadTemplate = () => {
        const link = document.createElement('a');
        link.href = MortalityVerificationTemplate;
        link.download = 'MortalityVerificationTemplate.xlsx'; 
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    const downloadFile = () => {
        const link = document.createElement('a');
        link.href = UploadTemplate;
        link.download = 'UploadTemplate.xlsx'; 
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    const downloadBadFile = () => {
        const link = document.createElement('a');
        link.href = badTemplate;
        link.download = 'BadTemplate.xlsx'; 
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    const downloadCSVFile = () => {
        const link = document.createElement('a');
        link.href = csvTemplate;
        link.download = 'UploadTemplateCSV.csv'; 
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const updateErrorCount = (index, newValue) => {
        const newErrorCount = [...errorCountData];

        newErrorCount[index] = newValue;
        //setErrorCountData(newErrorCount);
    };

    const removeFile = () => {
        setFiles([]);
        setHeaders([]);
        //session?.upload?.functions?.updateImportedList("columns", []);
        session?.edit?.functions?.updateImportedList("columns", []);
        session?.edit?.functions?.updateReport("sorting", null, []);
        session?.upload?.functions?.updateReport("errorCountData", initialErrorCountData);
    }

    function getTotalIndividuals(){
        const sessionUserString = localStorage.getItem('sessionUser');
        let sessionUser = null;
        if (sessionUserString) {
            sessionUser = JSON.parse(sessionUserString);
        }
        const accountNumber = sessionUser?.accountID;    
        session?.upload?.setData(prevData => ({
            ...prevData,
            accountNumber: accountNumber
        }));   

        session?.env?.functions?.buildFetchRequest("user/dashboard", null, null, null, "GET")
          .then(response => response.json())
          .then(resData => {
            if(resData.status === 200){
                setIndividualCount(resData?.accountData?.[accountNumber]?.summaryData?.totalIndividuals);
            }else{
                //setErrorStatus(resData.message);
            }
        });
    }
    function uploadMany(){
        let paramVals = {
            "individuals" : rows
        };
        session?.env?.functions?.buildFetchRequest("individual/addMany", paramVals)
          .then(response => response.json())
          .then(resData => {

            if(resData.status === 200){
                
            }else{
                //setErrorStatus(resData.message);
            }
        });
    }
    function uploadOne(){
        let paramVals = {
            stem : "individuals", recordID : data?.record_id, profile : data 
        };
        session?.env?.functions?.buildFetchRequest("individual/addOne", paramVals)
          .then(response => response.json())
          .then(resData => {
            
            if(resData.status === 200){
   
            }else{
                //setErrorStatus(resData.message);
            }
        });
    }
    return (
        <div className={`${fileTabSelected === 'many' ? 'oH' : 'oA'} f fC g`}>
            <div className='uploadContainer bR g f'
             onClick={resetDropdowns}
            >
                {viewType === "mobile" && fileTabSelected === 'many' &&
                    <>
                        <div className="f g cR s e gR1 mobileTitleRow">
                            <div className='f gC1 g cL'>
                                <div className='btnWIcon bold bR fMP ns dP fileTemplateBtn dG cC' onClick={() => {setFileTabSelected('single');}}>
                                        <div className='f cC lH'>Single</div>
                                        {/* <ScheduleIcon fontSize='medium'/> */}
                                </div> 
                            </div>
                            <div className="f cC t gC2">
                                <div className="viewBar cC">
                                    <ViewBarBtn
                                        isActive={uploadTabSelected}
                                        onClick={() => setUploadTabSelected(true)}
                                        label="Upload"
                                        alertType={"Status"}
                                        viewType={props?.viewType}
                                        session={session}
                                    />
                                    <ViewBarBtn
                                        isActive={!uploadTabSelected}
                                        onClick={() => setUploadTabSelected(false)}
                                        label="Columns"
                                        alertType={"Status"}
                                        viewType={props?.viewType}
                                        session={session}
                                    />
                                </div>
                            </div>
                            {!uploadTabSelected ? 
                                <div className='btnWIcon bold bR fMP ns dP cC gC3 fileTemplateBtn dG' onClick={() => {session?.env?.setOverlay("uploadHistory"); session?.upload?.functions?.getHistory(session);}}>
                                        {/* <div className='f cC lH'>History</div> */}
                                        <ScheduleIcon fontSize='medium'/>
                                </div> 
                                :
                                <div className='btnWIcon bold bR fMP ns dP cC gC3 fileTemplateBtn dG'  onClick={downloadTemplate}>
                                        {/* <div className='f cC lH'>History</div> */}
                                        <DownloadIcon fontSize='medium'/>
                                </div> 
                            }
                        </div>

                        <div className="f g cC gR2">
                            { (fileTabSelected === 'many' && uploadTabSelected) ? <div className='bold'> File Upload </div> : <div className='bold'> Columns</div> }
                        </div>
                    </>
                }

                {viewType !== "mobile" && 
                    <div className='cC g uploadTitle'>
                        <div className='viewbarGrid cL gC1 g f'>
                            <div className="uploadViewBar gC1">
                                <div className="gC1">
                                    <ViewBarBtn
                                        isActive={fileTabSelected === 'many'}
                                        onClick={() => setFileTabSelected('many')}
                                        label={"Many"}
                                        icon={<GroupIcon/>}
                                        alertType={"Status"}
                                        // viewType={viewType}
                                        session={session}
                                        currentPolicy={props?.currentPolicy}
                                    />
                                </div>
                                <div className="gC2">
                                    <ViewBarBtn                                   
                                        isActive={fileTabSelected === 'single'}
                                        onClick={() => setFileTabSelected('single')}
                                        label="Single"
                                        icon={<PersonIcon/>}
                                        // viewType={viewType}
                                        session={session}
                                    />
                                </div> 
                                <div className="gC3">
                                    <ViewBarBtn                                   
                                        isActive={fileTabSelected === 'sftp'}
                                        onClick={() => setFileTabSelected('sftp')}
                                        label="SFTP"
                                        icon={<LockIcon/>}
                                        // viewType={viewType}
                                        session={session}
                                    />
                                </div>                            
                            </div>
                        </div>

                        {fileTabSelected === 'many' && <div className='gC3 bold'> File Upload </div> }
                        {fileTabSelected === 'single' && <div className='gC3 bold'> Single Upload </div> }
                        {fileTabSelected === 'sftp' && <div className='gC3 bold'> Secure File Transfer Protocol (SFTP) </div> }

                        {fileTabSelected === 'single' && 
                            <div className='g gC4 f cC titleBtnsGrid dP'>
                                <div className='gC1'></div>
                                <div className='btnWIcon bold bR fMP ns dP cC gC2 fileTemplateBtn dG' onClick={() => {session?.env?.setOverlay("uploadHistory"); session?.upload?.functions?.getHistory(session);}}>
                                    <div className='f cC lH'>Upload History</div>
                                    <ScheduleIcon fontSize='medium'/>
                                </div> 
                            </div> 
                        }
                        {fileTabSelected === 'many' && 
                            <div className='g gC4 f cC manyTitleBtnsGrid dP'>
                                
                                <div className='btnWIcon bold bR fMP ns dP cC gC3 fileTemplateBtn dG' onClick={() => {session?.env?.setOverlay("uploadHistory"); session?.upload?.functions?.getHistory(session);}}>
                                    <div className='f cC lH'>{viewType === "full" ? 'Upload History' : 'History'}</div>
                                    <ScheduleIcon fontSize='medium'/>
                                </div> 
                                
                                <div className='btnWIcon bold bR fMP ns dP cR gC4 fileTemplateBtn templateGap dG' onClick={downloadTemplate}>
                                    <div className='f cC lH'>{viewType === "full" ? "File Template" : "Template"}</div>
                                    <DownloadIcon fontSize='medium'/>
                                </div> 
                            </div> 
                        }  
                        {fileTabSelected === 'sftp' && 
                            <div className='g gC4 f cC sftpTitleGrid dP'>
                                <div className='btnWIcon bold bR fMP ns dP cC gC2 fileTemplateBtn templateGap dG' onClick={downloadTemplate}>
                                    <div className='f cC lH'>File Template</div>
                                    <DownloadIcon fontSize='medium'/>
                                </div>  
                            </div>
                        }      
                    </div>
                }

                {fileTabSelected === 'single' && viewType === "mobile" && 
                    <div className='singleMobileTitle dP g'>
                        <div className='btnWIcon bold bR fMP ns dP fileTemplateBtn dG cC gC1' onClick={() => {setFileTabSelected('many');}}>
                                        <div className='f cC lH'>File Upload</div>
                        </div> 
                        <div className='cC bold gC2'> Single Upload </div> 
                    </div>
                }

                {fileTabSelected === 'many' &&
                    <div className={`rowContainer g ${viewType === "mobile" ? 'gR3' : 'gR2'} f`}>
                        <div className='uploadRow g gR1 f'>
                            {(viewType !== "mobile" || (uploadTabSelected && fileTabSelected === 'many')) &&
                                <div className='dP fileShareBox g gC1 f'>
                                    <div className='fileShareTitle bold cC gR1 g f'>

                                        {files?.length > 0 && !checkErrorCount() && (
                                            <div className='displayErrorsArea g f gC1 gR1'>
                                                {!displayErrors &&
                                                    <div className='displayErrorsBtn displayErrorsWidth gC1 cC bR dG' onClick={() => {toggleDisplayErrors();}}>Display Warnings<WarningAmberOutlinedIcon style={{color:'#f57f17', fontWeight:'500'}} fontSize='small'/></div>  
                                                }    
                                            </div>
                                        )}
                                        {files?.length > 0 && !checkErrorCount() && displayErrors && (

                                            <div className='displayErrorsGrid g f gC1 gR1'>           
                                                {displayErrors &&
                                                    <div className='displayErrorsBtn gC1 cC bR dG' onClick={() => {toggleDisplayErrors();}}>{viewType !== "mobile" && 'Hide'} <WarningAmberOutlinedIcon style={{color:'#f57f17'}} fontSize='small'/></div>  
                                                }  
                                                {displayErrors && !filterErrors &&     
                                                    <div className='displayErrorsBtn gC2 cC bR dG' onClick={() =>{toggleFilter();}}>{viewType !== "mobile" && 'Filter'}<FilterAltIcon color='action' fontSize='small'/></div> 
                                                }   
                                                {displayErrors && filterErrors &&     
                                                    <div className='displayErrorsBtn gC2 cC bR dG' onClick={() =>{toggleFilter();}}>{viewType !== "mobile" && 'Filter'}<FilterAltOffIcon color='action' fontSize='small'/></div> 
                                                }  
                                                {displayErrors && (editList.length > 0) &&     
                                                    <div className='displayErrorsBtn gC3 cC bR dG' onClick={() =>{undoEdit(session?.upload?.functions?.incrementErrorCount, session?.upload?.functions?.decrementErrorCount);}}>{viewType !== "mobile" && 'Undo'} <UndoIcon color='action' fontSize='small'/></div> 
                                                }   
                                                {displayErrors && (editList.length === 0) &&     
                                                    <div className='btnDisabled gC3 cC bR dG'>{viewType !== "mobile" && 'Undo'}<UndoIcon color='disabled' fontSize='small'/></div> 
                                                }  
                                                {displayErrors && (editList.length > 0) && 
                                                    <div className='displayErrorsBtn gC4 cC bR dG' onClick={() =>{props?.session?.env?.setOverlay("clearWarning");}}>{viewType !== "mobile" && 'Clear edits'} <RestoreIcon color='action' fontSize='small'/></div>
                                                } 
                                                {displayErrors && (editList.length === 0) &&     
                                                    <div className='btnDisabled gC4 cC bR dG'>{viewType !== "mobile" && 'Clear edits'} <RestoreIcon color='disabled' fontSize='small'/></div>
                                                }   
                                                
                                            </div>                          
                                        )}
                                        
                                        {/* <div className='gC2 gR1 fileShareHeader'>File Share</div> */}
                                        
                                        {files?.length > 0 && (
                                            <div className='removeFileGrid g f gC3 gR1'> 
                                                <div className='removeFile gC4 cC bR dG' onClick={() =>{
                                                    props?.session?.env?.setOverlay("removeWarning");
                                                }}
                                                >
                                                        {viewType === 'full' ? 'Remove File' : 'Remove'} <CancelIcon color='action' fontSize='small'/>  
                                                </div>    
                                            </div>                          
                                        )}
                                        {files?.length <= 0 && (
                                            <div className='removeFileGrid g f gC3 gR1'>                                               
                                                <div className='removeFileDisabled gC4 cC bR dG' style={{color:'gray'}}>Remove File <CancelIcon color='disabled' fontSize='small'/></div>
                                            </div>     
                                        )} 
                                    </div>
                                    
                                    <div className={`${files?.length !== 0 ? 'reportBuilder filePreviewContainer' : 'dropZoneContainer'} f g gR2 oH reportSheetZIndex`}>
                                        {files?.length === 0 &&                           
                                            <div className='f'>
                                                 <Dropzone 
                                                    className='f'
                                                    onDrop={(file) => {
                                                        if (session?.reporting?.data[session?.handler?.data?.currentAccountID]?.selectedReport?.branch === "summary") {
                                                            updateReporting(
                                                                `${session?.handler?.data?.currentAccountID}`, 
                                                                'selectedReport', 
                                                                session?.reporting?.data[session?.handler?.data?.currentAccountID]?.reset
                                                            );
                                                        }
                                                        setLoadingStatus(true); 
                                                        setFiles(file);
                                                    }}
                                                />
                                            </div>
                                        }
                                        {files?.length !== 0  && loadingStatus &&
                                            <div className='f'>
                                                <Skeleton
                                                    height={100}
                                                />
                                                <Skeleton                                        
                                                    height={100}
                                                />
                                                <Skeleton
                                                    height={100}
                                                />
                                                <CircularProgress disableShrink color="success" variant="indeterminate" fontSize="large"/> 
                                                <Skeleton
                                                    height={100}
                                                />
                                                <Skeleton
                                                    height={100}
                                                />
                                                <Skeleton
                                                    height={100}
                                                />                              
                                            </div>     
                                        }                        
                                        {files?.length !== 0  && !loadingStatus && !invalidUpload &&
                                            //<div className='f reportSheetZIndex reportSheetBuilder'>
                                            <ReportSheet 
                                                session={session} 
                                                importedList={editImportedList} 
                                                reset={resetDropdowns} 
                                                editCellDropdownHandler={{
                                                    data: editCellDropdownHandler,
                                                    set: setEditCellDropdownHandler
                                                }} 
                                            /> 
                                            //</div>
                                        }
                                        {files?.length !== 0 && !loadingStatus && invalidUpload && rows?.length < 10000 &&
                                            
                                            <div className='g cC f invalidUploadContentContainer'>
                                                <div className='invalidUploadContent f bC'><ErrorIcon fontSize='large'/></div>
                                            
                                                <div className='f invalidUploadContent tC'>
                                                    Uploaded file is not valid. Please refer to the file template.
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    
                                    <div className='fileShareDetails cL g gR3'>
                                        {files?.length > 0 && (
                                            <div className='fileShareDetail cC gC1 fileNameDetail gCW'>  
                                                {files[0].name} - {files[0].size > 1000000 ? ((files[0].size / 1000000).toFixed(2) + " MB") : ((files[0].size / 1000).toFixed(0) + " KB")}                
                                            </div>
                                        )}
                                        {files?.length <= 0 && (
                                            <div className='fileShareDetailInactive cC gC1' style={{color:'gray'}}> 
                                                No file inserted
                                            </div>
                                        )}
                                        {files?.length > 0 && rows?.length !== 1 && viewType !== "mobile" && (
                                            <div className={`${viewType === "mobile" ? 'gC3' : 'gC2'}`}>{invalidUpload ? 'N/A' : largeUpload ? largeUploadCount?.toLocaleString() : rows?.length?.toLocaleString()} Individuals </div>
                                        )}
                                        {files?.length > 0 && rows?.length === 1 && viewType !== "mobile" &&(
                                            <div className={`${viewType === "mobile" ? 'gC3' : 'gC2'}`}>{invalidUpload ? 'N/A' : rows?.length?.toLocaleString()} Individual </div>
                                        )}
                                        {files?.length <= 0 && (
                                            <div className={`${viewType === "mobile" ? 'gC3' : 'gC2'}`} style={{ color: 'gray' }}>
                                                Number of Individuals: N/A
                                            </div>
                                        )}

                                        {viewType !== "mobile" &&
                                            <div className='gC3 g cR e f'>
                                                <a href="mailto:help@abltech.com" target="_blank" rel="noopener noreferrer" className='gC3 link helpLink cC p '> Help<HelpOutlineIcon fontSize='medium'/></a>
                                            </div>
                                        }
                                        
                                        {files?.length > 0 && rows?.length !== 1 && viewType === "mobile" && (
                                            <div className={'btnWIcon dP bR gC3 cC p'}  onClick={() => {setUploadTabSelected(false);}}>View Details<ForwardOutlinedIcon/></div>
                                        )}
                                                                    
                                    </div>               
                                </div>
                            }

                            {(viewType !== "mobile" || (!uploadTabSelected && fileTabSelected === 'many')) &&
                                <div className={`columnsBox g dP ${viewType === "mobile" ? 'gC1' : 'gC2'}`}>
                                    {viewType !== "mobile" &&
                                        <div className='bold cC gR1 f'>
                                            Columns
                                        </div>
                                    }
                                    
                                    <div className='columnsContent gR2 f bR g'  style={{ backgroundColor: files?.length === 0 ? 'rgb(240,240,240)' : 'white' }}>
                                        <div className='fileDetailsBox f bR' id="targetElement" ref={containerRef}> 
                                            <div className='columnIconBox cC'> 
                                                {(files?.length === 0 || loadingStatus) && 
                                                    <div className='iconCircleGray cC'><BeenhereIcon style={{color:'lightgray'}}/></div>
                                                }
                                                {(files?.length > 0 && !loadingStatus) && checkHeaders(requiredHeaders, headers) === 0 &&
                                                    <div className='iconCircleGreen cC p' onClick={() => scrollToBox('box1')}><BeenhereIcon style={{color:'#8bc34a'}}/></div>
                                                }
                                                {(files?.length > 0 && !loadingStatus) && checkHeaders(requiredHeaders, headers) === 1 &&
                                                    <div className='iconCircleYellow cC p' onClick={() => scrollToBox('box1')}><BeenhereIcon style={{color:'#fbc02d'}}/></div>
                                                }
                                                {(files?.length > 0 && !loadingStatus) && checkHeaders(requiredHeaders, headers) === 2 &&
                                                    <div className='iconCircleRed cC p' onClick={() => scrollToBox('box1')}><BeenhereIcon style={{color:'#f44336'}}/></div>
                                                }
                                                <div className='hDivider' style={{width:'5%'}}></div>
                                                {(files?.length === 0 || loadingStatus) && 
                                                    <div className='iconCircleGray cC'><WarningIcon style={{color:'lightgray'}}/></div>
                                                }
                                                {(files?.length > 0 && !loadingStatus) && checkErrorCount(session?.upload?.data?.errorCountData) &&
                                                    <div className='iconCircleGreen cC p' onClick={() => scrollToBox('box2')}><WarningIcon style={{color:'#8bc34a'}}/></div>
                                                }
                                                {(files?.length > 0 && !loadingStatus) && !checkErrorCount(session?.upload?.data?.errorCountData) &&
                                                    <div className='iconCircleYellow cC p' onClick={() => scrollToBox('box2')}><WarningIcon style={{color:'#fbc02d'}}/></div>
                                                }
                                                <div className='hDivider' style={{width:'5%'}}></div>
                                                {(files?.length === 0 || loadingStatus)&& 
                                                    <div className='iconCircleGray cC'><DescriptionIcon style={{color:'lightgray'}}/></div>
                                                }         
                                                {(files?.length > 0 && !loadingStatus) && 
                                                    <div className='iconCircleGreen cC p' onClick={() => scrollToBox('box3')}><DescriptionIcon style={{color:'#8bc34a'}}/></div>
                                                }

                                            </div>

                                            <div id="box1" className={`requiredColumnsBox cC bR g fC dG ${
                                                    checkHeaders(requiredHeaders, headers) === 0 
                                                    ? 'correctIncluded' 
                                                    : checkHeaders(requiredHeaders, headers) === 1 
                                                    ? 'partialIncluded'
                                                    : (checkHeaders(requiredHeaders, headers) === 2 && files?.length > 0 && !loadingStatus)
                                                    ? 'incorrectIncluded'
                                                    : ''
                                                }`}
                                            >
                                                <div className='bold gR1 s cC'>Required</div>
                                                <div className='gR2 g dG f'>
                                                {requiredHeaders.map((header, index) => (    
                                                    <div className={`requiredElement cL dG ${upperCaseHeaders.includes(header?.toUpperCase()) ? 'correctHeader' : files?.length > 0 ? '': ''}`}key={index}>
                                                        { 
                                                        upperCaseHeaders.includes(header?.toUpperCase()) ? (
                                                            incorrectOrder.includes(header) ? 
                                                            <WarningIcon sx={{ color: 'gold' }}/> : 
                                                            <CheckCircleIcon sx={{ color: '#8bc34a' }} />
                                                        ) : (
                                                            <CheckCircleOutlineIcon color="disabled"/>
                                                        )
                                                        }
                                                        {" " + header}
                                                    </div>  
                                                ))}
                                                </div>
                                            </div>

                                            {files?.length === 0 &&
                                                <div className='vDivider' style={{height:'10%'}}></div>
                                            }

                                            {files?.length !== 0 &&  
                                                <div className='vDivider' style={{height:'5%'}}></div>
                                            }

                                            {files?.length === 0 &&
                                                <div className='noColumnsText cC'>Insert a file to view columns </div>
                                                //HighlightOffOutlinedIcon
                                            }

                                            {files?.length !== 0 &&   
                                                <div id="box2" className={`invalidEntriesBox cC bR dG g ${loadingStatus ? 'grayColorSet' : checkErrorCount(session?.upload?.data?.errorCountData) ? 'greenColorSet' : 'yellowColorSet'}`}>
                                                    <div className='bold gR1'>Warnings</div>

                                                    {!checkErrorCount(session?.upload?.data?.errorCountData) && (
                                                        //'invalidEntriesText'
                                                        <div className=''>
                                                            The accuracy of your data is key to ensuring our monitoring can be as accurate as possible     
                                                        </div>
                                                    )}

                                                    {!checkErrorCount(session?.upload?.data?.errorCountData) && (<div className='redText  gR3 bR'>Missing/Invalid Entries:</div>)}
                                                    {checkErrorCount(session?.upload?.data?.errorCountData) && !loadingStatus &&(<div className='noErrorsFoundBox cC bR gR2'>No errors found! <CheckCircleIcon sx={{ color: '#8bc34a' }}/></div>)}
                                                    {loadingStatus &&(<CircularProgress disableShrink color="success"/>)}
                                                    

                                                    {errorCountData && errorCountData['first_name'] !== 0 && (
                                                        <div className={`requiredElement invalidEntriesGrid g f ${animateFirst ? 'animateRequiredElement' : ''}`}> <div className='gC2'>First Name:</div> <span className='redText bold cR'>{errorCountData['first_name'].toLocaleString()}</span> </div>
                                                    )}
                                                    {errorCountData && errorCountData['last_name'] !== 0 && (
                                                        <div className={`requiredElement invalidEntriesGrid g f ${animateLast ? 'animateRequiredElement' : ''}`}> <div className='gC2'>Last Name:</div> <span className='redText bold cR'>{errorCountData['last_name'].toLocaleString()}</span> </div>
                                                    )}
                                                    {errorCountData && errorCountData['ssn'] !== 0 && (
                                                        <div className={`requiredElement invalidEntriesGrid g f ${animateSSN ? 'animateRequiredElement' : ''}`}> <div className='gC2'>SSN:</div> <span className='redText bold cR'>{errorCountData['ssn'].toLocaleString()}</span> </div>
                                                    )}
                                                    {errorCountData && errorCountData['dob'] !== 0 && (
                                                        <div className={`requiredElement invalidEntriesGrid g f ${animateDOB ? 'animateRequiredElement' : ''}`}> <div className='gC2'>Date of Birth:</div> <span className='redText bold cR'>{errorCountData['dob'].toLocaleString()}</span> </div>
                                                    )}
                                                    {errorCountData && errorCountData['city'] !== 0 && (
                                                        <div className={`requiredElement invalidEntriesGrid g f ${animateCity ? 'animateRequiredElement' : ''}`}> <div className='gC2'>City:</div> <span className='redText bold cR'>{errorCountData['city'].toLocaleString()}</span> </div>
                                                    )}
                                                    {errorCountData && errorCountData['state'] !== 0 && (
                                                        <div className={`requiredElement invalidEntriesGrid g f ${animateState ? 'animateRequiredElement' : ''}`}> <div className='gC2'>State:</div> <span className='redText bold cR'>{errorCountData['state'].toLocaleString()}</span> </div>
                                                    )}
                                                    {errorCountData && errorCountData['zip_code'] !== 0 && (
                                                        <div className={`requiredElement invalidEntriesGrid g f ${animateZip ? 'animateRequiredElement' : ''}`}> <div className='gC2'>Zip Code:</div> <span className='redText bold cR'>{errorCountData['zip_code'].toLocaleString()}</span> </div>
                                                    )}
                            
                                                </div>
                                            }

                                            {files?.length !== 0 &&  
                                                <div className='vDivider' style={{height:'5%'}}></div>
                                            }

                                            {files?.length !== 0 &&  
                                                <div id="box3" className={`g benefInfoBox cC bR dG ${!loadingStatus ? 'greenColorSet' : 'grayColorSet'}`}>
                                                    <div className='bold f'>Account Details</div>         
                                                    <div className='f'>{session?.account?.data[session?.handler?.data?.currentAccountID]?.name}</div>  
                                                    <div className='f'>Contract Limit: {session?.account?.data[session?.handler?.data?.currentAccountID]?.contractedRecords?.toLocaleString()}</div>                                                                                    
                                                        
                                                        <div className={`requiredElement g f ${!loadingStatus ? 'correctHeader' : ''}`}> 
                                                            <div className='g cC f'>Previous Individuals:</div> 
                                                            <div className='gR2' style={{height:'10px'}}></div>
                                                            <span className='gR3 bold cC f'> {individualCount?.toLocaleString()} </span>
                                                            <div className='gR4' style={{height:'10px'}}></div>
                                                        </div>
                                                        <div className={`requiredElement g f ${!loadingStatus ? 'correctHeader' : ''}`}> 
                                                            <div className='g cC f'>Currently Uploading:</div>
                                                            <div className='gR2' style={{height:'10px'}}></div>
                                                            <span className='gR3 bold cC f'>{invalidUpload ? 'N/A' : rows?.length?.toLocaleString()}</span>
                                                            <div className='gR4' style={{height:'10px'}}></div>
                                                        </div>                            
                                                        <div className='cC s e'><ErrorOutlineOutlinedIcon/><div>Upload will replace all previous individuals</div></div>   
                                                        
                                                </div> 
                                            }             
                                        </div>    
                                    </div>    
                                    
                                        {
                                            ((files?.length !== 0) && !invalidUpload) ?
                                                <div className='gR3 f saveUploadBtn'>
                                                    <div className='g cC f fC fR p'> 
                                                    
                                                        <div className='cC bold f'>
                                                            {/* <CardsPreview 
                                                                session={session} 
                                                                fileRows={rows} 
                                                                allHeaders={allHeaders} 
                                                                rowErrors={rowErrors} 
                                                                updateRows={updateRows} 
                                                                setFileRows={setFileRows}
                                                                errorCountData={errorCountData}                                                
                                                            />  */}
                                                            {
                                                                (session?.upload?.data?.contractLimit * 1.2)  <  rows?.length ?
                                                                    <div className='btnWIcon cC f bR'
                                                                        onClick={() => {props?.session?.env?.setOverlay("contractLimit");}}
                                                                    >
                                                                        <div className='f cC'>Save Upload </div>
                                                                        <SaveIcon/>
                                                                    </div>
                                                                    // <ContractLimit   
                                                                    //     session={session}                                       
                                                                    // />  
                                                                :     
                                                                    <div className='btnWIcon cC f bR'
                                                                        onClick={() => {props?.session?.env?.setOverlay("userAgreement");}}
                                                                    >
                                                                        <div className='f cC'>Save Upload </div>
                                                                        <SaveIcon/>
                                                                    </div>                                                         
                                                                    // <UserAgreement 
                                                                    //     fileRows={rowsList} 
                                                                    //     errorCountData={errorCountData}     
                                                                    //     session={session}  
                                                                    //     setFileRows={setFileRows}                                       
                                                                    // />  
                                                            }
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            :
                                            null
                                        }              
                                    

                                    {((files?.length !== 0) && !invalidUpload) ?
                                        null
                                        :
                                        <div className='gR3 f saveUploadInactive'>
                                            <Tooltip hasArrow label='Please upload a valid file' bg='#f5c2c7' placement='top' arrowSize={15}>
                                                <div className='cC f g fC fR bR'>
                                                    <div className='cC bold f bR saveUploadInactiveBorder'><div className='cC f'>Save Upload</div> <SaveIcon/></div>
                                                </div>
                                            </Tooltip>
                                        </div>
                                    }

                                </div>
                            }
                        </div>

                    </div>  
                }    
                {fileTabSelected === 'single' &&
                    <div className=''>
                        <div className='singleUploadContainer g' onClick={()=>{setSelectedState((prev) => ({ ...prev, showMenu: false })); setSelectedBenefState((prev) => ({ ...prev, showMenu: false }));}}>  
                            <div className='g gR1 gC2 internalBlock dP'>
                                <div className='bold cL gR1 dP dG'><TopicIcon/>Internal Information</div>
                                <div className='g internalSection gR2 dG cC'>  
                                    <InputField
                                        value={data?.internal_id}
                                        placeholder={viewType === "mobile" ? "ID" : "Internal ID"}
                                        functionPointer={session?.env?.functions?.updateInput}
                                        name="internal_id"
                                        setter={setData}
                                    />
                                    <InputField
                                        className='gC2'
                                        value={data?.internal_group}
                                        placeholder={viewType === "mobile" ? "Group" : "Internal Group"}
                                        functionPointer={session?.env?.functions?.updateInput}
                                        name="internal_group"
                                        setter={setData}
                                    />
                                </div>
                            </div>
                            <div className='g gR2 gC2 participantBlock dP'>
                                <div className='bold cL gR1 dP dG'><PersonIcon/>Individual Information <span style={{ color: 'red' }}>*</span></div> 
                                <div className='g nameSection gR2 dG'>
                                    <InputField
                                        value={data?.first_name}
                                        placeholder={"First Name *"}
                                        functionPointer={session?.env?.functions?.updateInput}
                                        name="first_name"
                                        setter={setData}
                                        {...(displayInvalid && invalidInputs.includes('first_name') ? { error:' '} : {})}
                                    />              
                                    <InputField
                                        value={data?.middle_name}
                                        placeholder={"Middle Name"}
                                        functionPointer={session?.env?.functions?.updateInput}
                                        name="middle_name"
                                        setter={setData}
                                    />
                                    <InputField
                                        value={data?.last_name}
                                        placeholder={"Last Name *"}
                                        functionPointer={session?.env?.functions?.updateInput}
                                        name="last_name"
                                        setter={setData}
                                        {...(displayInvalid && invalidInputs.includes('last_name') ? { error:' '} : {})}
                                    />
                                    <div className='suffixInputSize'>
                                        <InputField
                                            value={data?.suffix}
                                            placeholder={"Suffix"}
                                            functionPointer={session?.env?.functions?.updateInput}
                                            name="suffix"
                                            setter={setData}
                                        />
                                    </div>
                                </div>  
                                <div className='g genderSection gR3 dG'>
                                    {/* <div className='genderBox g'>         
                                            {data?.gender ?<div className="genderLabel inputFont cL gR1" htmlFor="gender-select">Gender</div> : <div className="genderLabel"> </div>}
                                            <select 
                                                className='genderField gR2'
                                                id="gender-select" 
                                                value={data?.gender} 
                                                onChange={handleGenderChange}
                                                // If gender is not selected, show the placeholder
                                                style={data.gender ? {} : { color: 'grey' }}
                                            >
                                                {!data.gender && <option value="" disabled >Gender *</option>}
                                                <option style={{ color:'black'}} value="Male">Male</option>
                                                <option style={{ color:'black'}} value="Female">Female</option>
                                            </select>
                                    </div> */}
                                    <div className='inputSize'>
                                        <InputField
                                            value={data?.ssn}
                                            placeholder={viewType !== "full" ? "SSN *" : "Social Security Number *"}
                                            functionPointer={session?.env?.functions?.updateInput}
                                            name="ssn"
                                            setter={setData}
                                            {...(displayInvalid && invalidInputs.includes('ssn') ? { error:' '} : {})}
                                        />
                                    </div>
                                    <div className='inputFont bC dobLabel'>{viewType === "mobile" ? "DOB*:" : "Date of Birth *:"}</div>
                                    <div className='g f dobField'>
                                        <InputField                                        
                                            value={data?.dob}
                                            type="date" 
                                            functionPointer={session?.env?.functions?.updateInput}
                                            name="dob"
                                            setter={setData}
                                        />
                                    </div>
                                </div>
                                <div className='g locationSection gR4 dG'>
                                    <InputField
                                        value={data?.city}
                                        placeholder={"City *"}
                                        functionPointer={session?.env?.functions?.updateInput}
                                        name="city"
                                        setter={setData}
                                    />
                                    <div className='g'>
                                        <div className='gR1' style={{height:'32px'}}>
                                            {data.state === "State *" || data.state === "" ? <div className='f'></div> : <div className='f cL dP inputFont'>State</div>}
                                        </div>
                                        <div className='gR2 stateDropdown' style={{backgroundColor:'white'}}> 
                                            <Dropdown 
                                                list={session?.individual?.data[session?.handler?.data?.currentAccountID]?.individualAttributesData?.state?.list}
                                                setShowMenu={(input) => {
                                                    setSelectedState((prev) => ({ 
                                                        showMenu: input ?? !prev.showMenu, 
                                                    }));
                                                }}
                                                default={"State *"}
                                                showMenu={selectedState?.showMenu}
                                                valuePointer="state"
                                                onClick={(value)=>{ 
                                                    setData(prevData => ({
                                                        ...prevData,
                                                        state: value
                                                    }));  
                                                    setSelectedState((prev) => ({ ...prev, showMenu: false }));
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <InputField
                                        value={data?.zip_code}
                                        placeholder={"Zip Code *"}
                                        functionPointer={session?.env?.functions?.updateInput}
                                        name="zip_code"
                                        setter={setData}
                                    />
                                </div>
                            </div>             
                            
                            {!addBeneficiary &&
                                <div className='vDivider gC2 ' style={{height:'30px'}}></div> 
                            }
                            {!addBeneficiary &&
                                <div className='g addBRow gC2 cC'>      
                                       
                                    <div className='addBeneficiary btnWIcon cC gC2 dP dG'  onClick={() => setAddBeneficiary(!addBeneficiary)}>
                                        <AddCircleOutlineOutlinedIcon fontSize='medium'/> Add Beneficiary
                                    </div>
                                </div>
                            }
                            {addBeneficiary &&
                                <div className='g gR3 gC2 beneficiaryBlock dP'>
                                    <div className='g gR1 bTitleRow'>
                                        <div className='cL gC1 bold dP dG'><GroupIcon/>Beneficiary Information</div>
                                        <div className='gC2 cR'>
                                            {viewType === "mobile" ? 
                                                <div className='removeBeneficiary gC2 cC btnWIcon dP' onClick={() => {setAddBeneficiary(!addBeneficiary); eraseBeneficiaryData();} }><HighlightOffOutlinedIcon/>Remove</div>
                                                :
                                                <div className='removeBeneficiary gC2 cC btnWIcon dP dG' onClick={() => {setAddBeneficiary(!addBeneficiary); eraseBeneficiaryData();} }><HighlightOffOutlinedIcon/>Remove Beneficiary</div>
                                            }                                        
                                        </div>
                                        
                                    </div>
                                    <div className='g bNameSection gR2 dG'>      
                                        <InputField
                                            value={data?.benef_first_name}
                                            placeholder={viewType !== "full" ? "First Name" : "Beneficiary First Name"}
                                            functionPointer={session?.env?.functions?.updateInput}
                                            name="benef_first_name"
                                            setter={setData}
                                        />
                                        <InputField
                                            value={data?.benef_middle_name}
                                            placeholder={viewType !== "full" ? "Middle Name" : "Beneficiary Middle Name"}
                                            functionPointer={session?.env?.functions?.updateInput}
                                            name="benef_middle_name"
                                            setter={setData}
                                        />
                                        <InputField
                                            value={data?.benef_last_name}
                                            placeholder={viewType !== "full" ? "Last Name" : "Beneficiary Last Name"}
                                            functionPointer={session?.env?.functions?.updateInput}
                                            name="benef_last_name"
                                            setter={setData}
                                        />
                                    </div>
                                    <div className='g bGenderSection gR3 dG'>
                                        {/* <div className='genderBox g'>         
                                                {data.bGender ?<div className="genderLabel inputFont cL gR1" htmlFor="gender-select">Beneficiary Gender</div> : <div className="genderLabel"> </div>}
                                                <select 
                                                    className='genderField gR2'
                                                    id="gender-select" 
                                                    value={data?.bGender} 
                                                    onChange={handleBGenderChange}
                                                    // If gender is not selected, show the placeholder
                                                    style={data.bGender ? {} : { color: 'grey' }}
                                                >
                                                    {!data.bGender && <option value="" disabled >Beneficiary Gender </option>}
                                                    <option style={{ color:'black'}} value="Male">Male</option>
                                                    <option style={{ color:'black'}} value="Female">Female</option>
                                                </select>
                                        </div> */}
                                        {viewType !== "full" ? <div className='dobLabel inputFont bC'>{viewType === "mobile" ? "DOB:" : "Date of Birth:"}</div> : <div className='dobLabel inputFont bC'>Beneficiary Date of Birth:</div>}

                                        <div className='g f dobField'>
                                            <InputField                                        
                                                value={data?.benef_dob}
                                                type="date" 
                                                functionPointer={session?.env?.functions?.updateInput}
                                                name="benef_dob"
                                                setter={setData}
                                            />
                                        </div>
                                        <div className='inputSize'>
                                            <InputField
                                                value={data?.benef_city}
                                                placeholder={viewType !== "full" ? "City" : "Beneficiary City"}
                                                functionPointer={session?.env?.functions?.updateInput}
                                                name="benef_city"
                                                setter={setData}
                                            />
                                        </div>
                                    </div>
                                    <div className='g bLocationSection gR4 dG'>                
                                        <div className='g'>
                                            <div className='gR1' style={{height:'32px'}}>
                                                {data.benef_state === "Beneficiary State" || data.benef_state === "" ? <div className='f'></div> : viewType !== "full" ? <div className='f cL dP inputFont'>State</div> : <div className='f cL dP inputFont'>Beneficiary State</div>}
                                            </div>
                                            <div className='gR2 stateDropdown' style={{backgroundColor:'white'}}> 
                                                <Dropdown 
                                                    list={session?.individual.data[session?.handler?.data?.currentAccountID].individualAttributesData?.benef_state.list}
                                                    setShowMenu={(input) => {
                                                        setSelectedBenefState((prev) => ({ 
                                                            showMenu: input ?? !prev.showMenu, 
                                                        }));
                                                    }}
                                                    default={viewType==="mobile" ? "State" : "Beneficiary State"}
                                                    showMenu={selectedBenefState?.showMenu}
                                                    valuePointer="benef_state"
                                                    onClick={(value)=>{ 
                                                        setData(prevData => ({
                                                            ...prevData,
                                                            benef_state: value
                                                        }));  
                                                        setSelectedBenefState((prev) => ({ ...prev, showMenu: false }));
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <InputField
                                            value={data?.benef_zip}
                                            placeholder={viewType !== "full" ? "Zip Code" : "Beneficiary Zip Code"}
                                            functionPointer={session?.env?.functions?.updateInput}
                                            name="benef_zip"
                                            setter={setData}
                                        />
                                        <div className='g'>
                                            <div className='gR1' style={{height:'32px'}}>
                                                {data.benef_relationship === "Beneficiary Relationship" || data.benef_relationship === "" ? <div className='f'></div> : viewType !== "full" ? <div className='f cL dP inputFont'>Relationship</div> : <div className='f cL dP inputFont'>Beneficiary Relationship</div>}
                                            </div>
                                            <div className='gR2 stateDropdown' style={{backgroundColor:'white'}}> 
                                                <Dropdown 
                                                    list={session?.individual.data[session?.handler?.data?.currentAccountID].individualAttributesData?.benef_relationship.list}
                                                    setShowMenu={(input) => {
                                                        setSelectedBenefRelationship((prev) => ({ 
                                                            showMenu: input ?? !prev.showMenu, 
                                                        }));
                                                    }}
                                                    default={viewType==="mobile" ? "Relationship" : "Beneficiary Relationship"}
                                                    showMenu={selectedBenefRelationship?.showMenu}
                                                    valuePointer="benef_relationship"
                                                    onClick={(value)=>{ 
                                                        setData(prevData => ({
                                                            ...prevData,
                                                            benef_relationship: value
                                                        }));  
                                                        setSelectedBenefRelationship((prev) => ({ ...prev, showMenu: false }));
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='g bRelationshipSection gR5'>
                                        
                                    </div>                 
                                </div>
                            }
                            
                            <div className='gR5 g saveUploadBox gC2'>
                                {(displayInvalid && (invalidInputs.length > 0)) && 
                                    <div className='errorText gR1'>
                                        Invalid inputs for: {invalidInputs.join(', ')}                                    
                                    </div>
                                }
                                {(requiredInputs) ?
                                        <div className='cC gR2'>
                                                <div className='cC btnWIcon singleUploadBtn p' 
                                                onClick={() => { 
                                                    if (handleSingleUpload()) {
                                                        props?.session?.individual?.setData(`${session?.handler?.data?.currentAccountID}.selectedProfile.upload`, [{
                                                            stem : "individuals", upload : true, profile: data
                                                        }]);
                                                        props?.session?.individual?.setData(`${session?.handler?.data?.currentAccountID}.selectedProfile.active`, {
                                                            stem : "individuals", upload : true, profile : data
                                                        });
                                                        props?.session?.env?.setOverlay("individualProfile");
                                                    } else {
                                                        handleInvalidUpload();
                                                    }
                                                    }}>
                                                    Save Upload
                                                    {/* <SingleUploadPreview inputs={data} missingHeaders={missingHeaders}/> */}
                                                </div>
                                        </div>
                                        :
                                        <div className='cC gR2'>
                                            <Tooltip hasArrow label='Fill out the required fields to upload' bg='#f5c2c7' placement='top' arrowSize={10}>
                                                <div className='cC singleUploadBtn singleUploadBtnInactive'>Save Upload</div>
                                            </Tooltip>
                                        </div>      
                                }        
                            </div>  
                        </div>
                    </div>
                }

                {fileTabSelected === 'sftp' &&
                    <div className='g sftpContainer dP'>
                        
                        <div className='f gR1 gC2 dP'>
                            {sftpFiles.length !== 0 &&
                                <div className='cC f bR g sftpSummaryBlock'>
                                    <div className='cC g dG' style={{ gap: '40px' }}>
                                        <div className='cC dP bR dG g sftpFileBlock' > 
                                            <DescriptionIcon style={{color:'black', fontSize:'75px'}}/>
                                            {sftpFiles[0]?.name} 
                                            <div> 
                                                {sftpFiles[0].size > 1000000 ? ((sftpFiles[0].size / 1000000).toFixed(2) + " MB") : ((sftpFiles[0].size / 1000).toFixed(0) + " KB")}
                                            </div>
                                        </div>
                                        
                                        <div className='bR dP p btnWIcon' onClick={() => {setSftpFiles([]);}}>Remove File</div>
                                    </div>
                                </div>
                            }
                                
                            {sftpFiles.length === 0 &&
                                <Dropzone 
                                    className='f'
                                    onDrop={(file) => {
                                        if (session?.reporting?.data[session?.handler?.data?.currentAccountID]?.selectedReport?.branch === "summary") {
                                            updateReporting(
                                                `${session?.handler?.data?.currentAccountID}`, 
                                                'selectedReport', 
                                                session?.reporting?.data[session?.handler?.data?.currentAccountID]?.reset
                                            );
                                        }
                                        setLoadingStatus(true); 
                                        setSftpFiles(file);
                                    }}
                                    type={'sftp'}
                                />
                            }
                            
                        </div>
                        <div className='gR2 gC2 sftpSaveBtn g'>
                            <div className='gC2 f saveUploadBtn'>
                            
                                <div className={`btnWIcon ${sftpFiles.length === 0 ? 'inactive' : ''} cC f bR`}
                                    onClick={() => {sftpFiles.length !== 0 && props?.session?.env?.setOverlay({name:"sftpConfirmation", props:sftpFiles[0]});}}
                                >
                                    <div className='f cC'>Save Upload</div>
                                    <SaveIcon fontSize='large'/>
                                </div>
                                
                            </div>
                            
                        </div>

                    </div>
                }
            </div>
        </div>
    )
}
export default Upload;